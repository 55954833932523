import React from "react"
import { Link } from "gatsby"
import marked from "marked"

const HackathonWinners = props => {
  const getMarkdownText = hackathonDetails => {
    if (
      hackathonDetails &&
      (hackathonDetails !== null || hackathonDetails !== undefined)
    ) {
      var rawMarkup = marked(hackathonDetails, { sanitize: true })
      return { __html: rawMarkup }
    }
  }

  return (
    <>
      <div className="activites_row">
        <h2>{props?.hackathonDetails?.activities?.hackathon?.title}</h2>
      </div>
      <div
        className="activites_row"
        style={{ whiteSpace: "pre-wrap" }}
        dangerouslySetInnerHTML={getMarkdownText(
          props?.hackathonDetails?.activities?.hackathon
            ?.hackathonWinnerDescription?.hackathonWinnerDescription
        )}
      ></div>
      {props?.hackathonWinners?.map((item, index) => {
        return (
          <div className="hack_block">
            <h3 style={{ color: item?.fontColor }}>{item?.position}</h3>
            <div className="hack_block_col">
              <span>{item?.teamName}</span>
              <div
                dangerouslySetInnerHTML={getMarkdownText(
                  item?.projectDescription?.projectDescription
                )}
              ></div>
              {/* <a>read more</a> */}
            </div>
            <div className="hack_block_col">
              {item?.projectVideoUrl && (
                <iframe
                  width="100%"
                  height="315"
                  src={item?.projectVideoUrl}
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              )}
              {item?.projectMedia &&
                item?.projectMedia?.file?.contentType?.includes("image") && (
                  <img
                    src={item?.projectMedia?.file?.url}
                    alt="project-image"
                    style={{ width: "100%", height: "315px" }}
                  />
                )}
              {item?.projectMedia &&
                item?.projectMedia?.file?.contentType?.includes("video") && (
                  <video controls style={{ width: "100%", height: "315px" }}>
                    <source
                      src={item?.projectMedia?.file?.url}
                      type={item?.projectMedia?.file?.contentType}
                    />
                  </video>
                )}
            </div>
          </div>
        )
      })}

      {/* <div className="activites_row">
        <p>
          View other projects from the hackathon{" "}
          <a
            href={
              props?.hackathonWinners?.activities?.hackathon?.otherProjectsUrl
            }
            target="_blank"
            rel="noreferrer noopener"
          >
            here.
          </a>
        </p>
      </div> */}
      <div className="activites_row">
        <div
          dangerouslySetInnerHTML={getMarkdownText(
            props?.hackathonDetails?.activities?.hackathon?.hackathonDisclaimer
              ?.hackathonDisclaimer
          )}
        ></div>
      </div>
    </>
  )
}

export default HackathonWinners
