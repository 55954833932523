import React, { useState, useEffect } from "react"
import { Link, graphql } from "gatsby"
import PageLayout from "../components/hrbfpagelayout"
import HackathonDetails from "../components/hackathondetails"
import HackathonWinners from "../components/hackathonwinners"
import Head from "../components/head"

export const query = graphql`
  query($slug: String!) {
    contentfulEvents(slug: { eq: $slug }) {
      title
      startDate(formatString: "MMMM D")
      eventEndDate: endDate(formatString: "D, YYYY")
      endDate(formatString: "MMMM D, YYYY")
      eventStartMonth: startDate(formatString: "MMMM")
      eventEndMonth: endDate(formatString: "MMMM")
      displayName
      slug
      registrationUrl
      registrationCtaText
      transparentHeader
      headerColor
      bannerLogo {
        file {
          url
        }
      }
      bannerImageLeft {
        file {
          url
        }
      }
      bannerImageRight {
        file {
          url
        }
      }
      bannerImageLeftMobile {
        file {
          url
        }
      }
      bannerImageRightMobile {
        file {
          url
        }
      }
      activities {
        title
        subtitle
        hackathon {
          title
          registrationUrl
          status
          statusNote
          details {
            details
          }
          firstPlace
          secondPlace
          thirdPlace
          registrationDetails {
            registrationDetails
          }
          faq {
            title
            details {
              details
            }
          }
          hackathonWinners {
            title
            fontColor
            teamName
            position
            projectDescription {
              projectDescription
            }
            readMoreUrl
            projectVideoUrl
            projectMedia {
              file {
                url
                contentType
              }
            }
          }
          hackathonWinnerDescription {
            hackathonWinnerDescription
          }
          hackathonDisclaimer {
            hackathonDisclaimer
          }
        }
      }
      seoMetadata {
        title
        description {
          description
        }
      }
    }
  }
`
const Hackathon = props => {
  let registerUrl
  if (typeof window !== "undefined") {
    if (window.location.href.includes("?")) {
      let currentLocation = window.location.href.split("?")
      if (props?.data?.contentfulEvents?.registrationUrl.includes("?")) {
        registerUrl =
          props?.data?.contentfulEvents?.registrationUrl +
          "&" +
          currentLocation[1]
      } else {
        registerUrl =
          props?.data?.contentfulEvents?.registrationUrl +
          "?" +
          currentLocation[1]
      }
    } else {
      registerUrl = props?.data?.contentfulEvents?.registrationUrl
    }
  }
  const [scrollPosition, setScrollPosition] = useState(0)
  useEffect(() => {
    const close = e => {
      setScrollPosition(window.pageYOffset)
    }
    window.addEventListener("scroll", close)
  })

  return (
    <PageLayout
      title={props?.data?.contentfulEvents?.displayName?.toUpperCase()}
      logo={props?.data?.contentfulEvents?.bannerLogo}
      date={
        props?.data?.contentfulEvents?.startDate === null
          ? props?.data?.contentfulEvents?.endDate
          : props?.data?.contentfulEvents?.eventStartMonth ===
            props?.data?.contentfulEvents?.eventEndMonth
          ? `${props?.data?.contentfulEvents?.startDate} - ${props?.data?.contentfulEvents?.eventEndDate}`
          : `${props?.data?.contentfulEvents?.startDate} - ${props?.data?.contentfulEvents?.endDate}`
      }
      slug={props?.data?.contentfulEvents?.slug}
      registrationUrl={registerUrl}
      headerbtn={props?.data?.contentfulEvents?.registrationCtaText}
      bannerImageLeft={props?.data?.contentfulEvents?.bannerImageLeft}
      bannerImageLeftMobile={
        props?.data?.contentfulEvents?.bannerImageLeftMobile
      }
      bannerImageRight={props?.data?.contentfulEvents?.bannerImageRight}
      bannerImageRightMobile={
        props?.data?.contentfulEvents?.bannerImageRightMobile
      }
      transparentHeader={props?.data?.contentfulEvents?.transparentHeader}
      scroll={scrollPosition}
    >
      <Head
        title={props?.data?.contentfulEvents?.seoMetadata?.title}
        description={
          props?.data?.contentfulEvents?.seoMetadata?.description?.description
        }
      />

      <div className="activites_body">
        <div className="activites_back">
          <Link
            to={`/events/${props?.data?.contentfulEvents?.slug}/activities`}
          >
            &lt; &nbsp;&nbsp;Activities
          </Link>
        </div>
        {props?.data?.contentfulEvents?.activities?.hackathon?.status ===
          "Registration Closed" && (
          <div className="activites_row" style={{ textAlign: "center" }}>
            <h2>
              {props?.data?.contentfulEvents?.activities?.hackathon?.statusNote}
            </h2>
          </div>
        )}
        {props?.data?.contentfulEvents?.activities?.hackathon?.status ===
          "Registration Open" && (
          <HackathonDetails hackathonDetails={props?.data?.contentfulEvents} />
        )}

        {props?.data?.contentfulEvents?.activities?.hackathon?.status ===
          "Winners Announced" && (
          <HackathonWinners
            hackathonDetails={props?.data?.contentfulEvents}
            hackathonWinners={
              props?.data?.contentfulEvents?.activities?.hackathon
                ?.hackathonWinners
            }
          />
        )}
      </div>
    </PageLayout>
  )
}
export default Hackathon
