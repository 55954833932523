import React from "react"
import marked from "marked"

const HackathonDetails = props => {
  const getMarkdownText = hackathonDetails => {
    if (
      hackathonDetails &&
      (hackathonDetails !== null || hackathonDetails !== undefined)
    ) {
      var rawMarkup = marked(hackathonDetails, { sanitize: true })
      return { __html: rawMarkup }
    }
  }
  return (
    <>
      <div className="activites_row">
        <h2>{props?.hackathonDetails?.activities?.hackathon?.title}</h2>
      </div>
      <div
        className="activites_row"
        style={{ whiteSpace: "pre-wrap" }}
        dangerouslySetInnerHTML={getMarkdownText(
          props?.hackathonDetails?.activities?.hackathon?.details?.details
        )}
      ></div>

      <div className="activites_row">
        <h5>Prizes:</h5>
        <p>
          <span className="first">1st Place:</span>{" "}
          {props?.hackathonDetails?.activities?.hackathon?.firstPlace} <br />
          <span className="second">2nd Place:</span>{" "}
          {props?.hackathonDetails?.activities?.hackathon?.secondPlace}
          <br />
          <span className="third">3rd Place:</span>{" "}
          {props?.hackathonDetails?.activities?.hackathon?.thirdPlace}
        </p>
      </div>
      <div className="activites_row">
        <h5>Registration:</h5>
        <div
          className="register_details"
          style={{ whiteSpace: "pre-wrap" }}
          dangerouslySetInnerHTML={getMarkdownText(
            props?.hackathonDetails?.activities?.hackathon?.registrationDetails
              ?.registrationDetails
          )}
        ></div>
        {props?.hackathonDetails?.activities?.hackathon?.registrationUrl && (
          <a
            className="register"
            target="_blank"
            rel="noreferrer noopener"
            href={
              props?.hackathonDetails?.activities?.hackathon?.registrationUrl
            }
          >
            REGISTER
          </a>
        )}
      </div>
      <div className="activites_row">
        <h5> FAQs:</h5>
        {props?.hackathonDetails?.activities?.hackathon?.faq?.map(
          (item, index) => {
            return (
              <p>
                <span>{item?.title}</span>
                <br />
                {item?.details?.details}
              </p>
            )
          }
        )}
      </div>
    </>
  )
}

export default HackathonDetails
